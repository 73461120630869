import styled from 'styled-components'

const Wrapper = styled.div`
    a {
        color: ${props => props.theme.colors.white};
        margin: 50px 0 10px;
        &:hover {
            text-decoration: none;
            color: ${props => props.theme.colors.aquaBlue};
        }
    }

    .automatedLogo {
        margin: 60px 0 70px 0;
    }

    @media (max-width: 450px) {
        img {
            width: 100%;
            height: auto;
        }
    }
`

export { Wrapper }
