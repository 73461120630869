import React from 'react'
import { Field } from 'react-final-form'

import Button from '../Button'
import FormConfirmation from '../FormConfirmation'
import InputErrorMessage from '../InputErrorMessage'
import InputTextarea from '../InputTextarea'
import InputTextfield from '../InputTextfield'
import { Wrapper } from './styles'
import { composeValidators, required, emailValidator, phoneNumber } from './../../utils/validation'

const FormComponent = ({
  form,
  focusedReason,
  handleSubmit,
  pristine,
  submitError,
  submitSucceeded,
  submitting,
  successMessage,
  values,
  teal = false,
  ...props
}) => {
  const formSubmittedSuccessfully = submitSucceeded && successMessage
  return (
    <Wrapper>
      {formSubmittedSuccessfully ? (
        <FormConfirmation dark>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
        </FormConfirmation>
      ) : (
        <form onSubmit={handleSubmit}>
          <Field
            component={InputTextfield}
            teal={teal}
            id="company"
            label="Company"
            name="company"
            type="text"
            validate={required}
          />

          <Field
            component={InputTextfield}
            teal={teal}
            id="country"
            label="Country"
            name="country"
            type="text"
            validate={required}
          />

          <Field
            component={InputTextfield}
            teal={teal}
            id="website"
            label="Website"
            name="website"
            type="text"
            validate={required}
          />

          <Field
            component={InputTextfield}
            teal={teal}
            id="email"
            label="Email"
            name="email"
            type="email"
            validate={composeValidators(required, emailValidator)}
          />

          <Field
            autoComplete="tel"
            teal={teal}
            component={InputTextfield}
            name="phone"
            label="Telephone"
            type="text"
            validate={composeValidators(required, phoneNumber)}
          />

          {!submitSucceeded && submitError && <InputErrorMessage dangerouslySetInnerHTML={{ __html: submitError }} />}

          <Button fullWidth level="secondary" type="submit" addTopPadding={true} addBottomPadding={true} disabled={submitting || submitSucceeded}>
            Try Demo
          </Button>
        </form>
      )}
    </Wrapper>
  )
}

export default FormComponent
