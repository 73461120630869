import React from 'react'
import { Wrapper } from './styles'
import { Link } from 'gatsby'

import ArchZoopayLogo from '../../images/archZoopayLogo.svg'

const LinksBelow = () => {
    return (
        <Wrapper className="col-md-7 col-12">
            <div className="row justify-content-around text-centered">
                <div className="col-6 ">
                    <Link to='/privacy-policy'>Privacy policy</Link>
                </div>
                <div className="col-6 ">
                    <Link to='/contact'>Contact Us</Link>
                </div>
            </div>
            <div className="col-12 text-centered automatedLogo">
                <img src={ArchZoopayLogo} alt="Automated Real-Time Currency Hedging" />
            </div>
        </Wrapper>
    );
}

export default LinksBelow
