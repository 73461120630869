import styled from 'styled-components'

export const Wrapper = styled.div`
  input{
    &[type='email'],
    &[type='number'],
    &[type='password'],
    &[type='text'] {
      border: 1px solid ${props => props.theme.colors.white}; 
      caret-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white};
      font-size: ${props => props.theme.type.sizes.base};
      transition: ${props => props.theme.animations.transition};
      ${props =>
      props.error &&
        css`
          border: 1px solid ${props => props.theme.colors.red};
          color: ${props => props.theme.colors.red};
      `}


      
    }
  }
`
