import React from 'react'

import { Link } from 'gatsby'
import styled from 'styled-components'
import FormTryDemo from '../components/FormTryDemo'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import Section from '../components/Section'
import SlimTitle from '../components/SlimTitle'
import LinksBelow from '../components/LinksBelow'
import ArchDemoLogo from '../images/archDemoLogo.svg'


const Demo = () => {
  const seoNode = {
    title: 'Zoopay - Try Demo',
    description:
      'Gain visibility and control of your foreign exchange exposure, help ensure your international profitability.',
  }

  const H5 = styled.h5`
    color: ${props => props.theme.colors.white};
    padding: 0 0 32px;
    span {
      color: ${props => props.theme.colors.aquaBlue};
    }
  `

  return (
    <Layout dark teal>
      <SEOPageTitle title={seoNode.title} />
      <SEO postNode={seoNode} pagePath="contact" customTitle customDescription />
      <Section borderBottom={false} teal>
        <div className="row justify-content-around">
          <div className="col-md-7 col-12 text-centered">
            <SlimTitle><img src={ArchDemoLogo} alt="Try Arch demo" /></SlimTitle>
            <H5>Message about a demo<span className="aqua-blue">.</span></H5>
          </div>
          <div className="col-md-7 col-12">
            <FormTryDemo />
          </div>
          <LinksBelow />
        </div>
      </Section>
    </Layout>
  )
}

export default Demo
